import React  from 'react';
import logo from '../../data/appData/appImages/entete/logo1.png'
import logo2 from '../../data/appData/appImages/entete/sofoodText-rouge-adresse-halal2.png'
import './appHeaderCss.css'
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import { faMapMarker } from '@fortawesome/fontawesome-free-solid';

function Header(){

    return(





        <div>
            <div id='bandeauId' className="bandeauClassResp">

                <div id='logoDiv' className="logoDivClassResp">

                    
                    <img className='logoImage1'   src={logo} alt='pizzeria royal fast food Reims' />

    
                </div>

                <div className="logoDiv2 logoDiv2ClassResp">

                    <img className='logoImage2'   src={logo2} alt='pizzeria royal fast food Reims' />
                

                </div>


                
            </div>
            
           

        </div>



    );
}
export default Header